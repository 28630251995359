import { Container } from '#app/components/container.tsx';
import { FadeIn } from '#app/components/fade-in.tsx';

export default function Index() {
	return (
		<Container className="flex min-h-[calc(100vh-100px)] items-center">
			<FadeIn className="max-w-4xl">
				<h1 className="relative font-display text-5xl font-medium tracking-tight text-primary-darker [text-wrap:balance] sm:text-7xl">
					Your go-to-place for building digital products
					<svg
						// width="261"
						// height="260"
						viewBox="0 0 261 260"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="absolute right-0 top-[10%] z-10 h-[195px] w-[196px] -translate-y-1/2 translate-x-1/2 transform sm:h-[260px] sm:w-[261px]"
					>
						<path
							d="M165.802 184.465L118.997 260L125.343 182.079L34.9058 219.45L99.164 159.817L0 163.792L78.538 134.373L0 74.7401L86.471 89.052V0L118.997 89.052L165.802 10.3364L149.143 97.798L217.368 29.419L165.802 112.905L245.927 81.101L165.802 143.914H261L165.802 184.465Z"
							fill="#F55E49"
						/>
					</svg>
				</h1>
				<p className="mt-6 text-xl text-primary-darker">
					We build products centered on design, technology and good business.
				</p>
			</FadeIn>
		</Container>
	);
}
